.order_container {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.oder_card_container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    border-bottom: 1px solid white;
    padding-bottom: 25px;
}
.text_container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.oder_card_img {
    width: 90%;
}

.oder_card_wraper {
    width: 40%;
    cursor: pointer;
}

.oder_card_description {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 800px) {
    .text_container {
        flex-direction: column;
        gap: 25px;
        align-items: center;
    }
    .oder_card_img {
        width: 100%;
    }
    .oder_card_title {
        text-align: center;
    }
    .oder_card_wraper {
        width: 100%;
    }

    .oder_card_description {
        width: 100%;
    }
}
