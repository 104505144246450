@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,200;0,300;0,400;0,500;1,400;1,500&family=Open+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Source+Sans+Pro:wght@400;600;700&family=Work+Sans:wght@500&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body[data-theme="dark"] {
    --colors-text: black;
    --colors-bg: #d4e0d3;
    --shadow: rgba(5, 5, 5, 0.2) 0px 0px 8px;
    --dotColor:#9c9c9c;    
    --dotActiveColor:#279b03;
}

body[data-theme="light"] {
    --colors-text: white;
    --colors-bg: black;
    --shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    --dotColor:#dfdfdf;    
    --dotActiveColor:#30c801;
}

body {
    margin: 0;
    padding: 0;
    /* width: 100vw; */
    color: var(--colors-text);
    background: var(--colors-bg);
    font-family: "Work Sans", sans-serif;
    overflow-x: hidden;
}
