css Копировать код .products {
    font-family: Arial, sans-serif;
    padding: 20px;
}

.products_container {
    max-width: 1000px;
    margin: auto;

    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.video_block {
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.trash_gif {
    width: 50%;
}

.tech_specs {
    margin-top: 20px;
}

.title {
    text-align: center;
}

.subtitle {
    margin-top: 20px;
}

p {
    line-height: 1.6;
}

.desc_list {
    /* list-style-type: none; */
    padding: 0;
}

.desc_item {
    margin-bottom: 10px;
}

.desc_sublist {
    margin-top: 5px;
    margin-left: 20px;
    list-style-type: none;
}

.desc_subitem {
    margin-bottom: 5px;
}

@media screen and (max-width: 800px) {
    .trash_gif {
        width: 95%;
    }
}
