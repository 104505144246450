.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.cards {
    position: relative;
    margin: 20px;
    width: 30%;
    height: 300px;
    min-width: 300px;
    max-width: 450px;
    border-radius: 15px;
    border: 2px solid rgb(124, 107, 107);
    cursor: pointer;
    transition: 0.3s;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.background-div {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(55, 52, 52, 0.51); */
}

.game-title {
    width: 100%;
    height: 100px;
    text-align: center;
    align-items: center;
    font-size: 35px;
    font-weight: 900;
    color: white !important;
    z-index: 2;
}

.play-button {
    display: flex;
    width: 150px;
    height: 50px;
    align-items: center;
    text-align: center;
    color: white !important;
    border-radius: 10px;
    font-size: 25px;
    background-color: blue !important;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}
.cards:hover {
    transform: scale(1.1);
}
