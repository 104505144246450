.message-open {
    overflow: hidden;
    height: 40px;
    transition: height 0.2s;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.message-close {
    transition: height 0.2s;
    overflow: hidden;
    height: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.success-text {
    color: rgb(68, 255, 47);
    margin: 18px auto;
}

.error-text {
    color: red;
    margin: 18px auto;
}
