.slide{
    overflow: hidden;
}
.slider-block {
    padding: 22px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swiper-pagination-bullet {
    background-color: white !important;
    z-index: 12;
}

.swiper-pagination-bullet-active {
    background-color: #00ff48 !important;
}



.standartWide { 
    width: 100%;
    position: relative; 
    overflow: hidden; 
} 
 
.standartWide:before { 
    display: block; 
    content: ""; 
    padding-bottom: 100%;/*(height/width)*100*/ 
} 
 
.standartWide img { 
    position: absolute; 
    width: 100%; 
    height: 100%; 
    border-radius: 50%;
    object-fit: cover; 
    top: 0; 
    left: 0; 
}
.teatcher-description {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
}

.teatcher-description h3 {
    width: 100%;
    text-align: center;
    user-select: none;
    font-size: 18px;
    line-height: 1.5em;
}
.teatcher-description p{
    user-select: none;
    opacity: 0.8;
    font-size: 16px;
    line-height: 1.3em;
}


#Serine {
    object-position: 0cm -1.1cm;
}

#Gevorg {
    object-position: 0cm 0cm;
}
.slick-dots li:hover button:before  {
    color: var(--dotActiveColor);
}
.slick-dots li button:before  {
    color: var(--dotColor);
   font-size: 8px;
   transition: .3s;
}

.slick-dots li.slick-active button:before  {
    color: var(--dotActiveColor);
}
@media screen and (max-width: 960px) {
.slider-block {
        padding: 18px;
        margin: 18px 0;
    }
   

}
@media screen and (max-width: 600px) {
    p {
        font-size: 14px;
    }

    .slider-block {
        padding: 18px;
    }

}
