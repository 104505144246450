.footer {
    width: 100%;
    /* max-width: 1260px;
    margin: 0 auto; */
    
    position: relative;
    background: var(--colors-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--shadow);
    height: 440px;overflow: hidden;
    z-index: 99;
}
#map div.poi-info-window .view-link {
       display:none;
}
.map{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.map iframe{
    width: 100%;
}
.it-artashat {
    text-align: center;
    color: #30c801;
}

.footer-left {
    max-width: 350px;
    width: 100%;
    flex-direction: column;
    display: flex;
    overflow: hidden;
    align-items: center;
    gap: 15px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--colors-bg);
    z-index: 11;
}

.footer_container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.footer_container.phone_link {
    text-decoration: none;
    color: var(--colors-text);
}


.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #222;
}

.footerContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.footerSection {
    margin-bottom: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerSection h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.links_list {
    list-style: none;
    padding: 0;
    margin: 0;

    li a {
        color: #fff;
        text-decoration: none;
        font-weight: 500;
    }
}

.socialIcons {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}

.iconLink {
    width: 40px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.link_span {
    margin-left: 20px;
    cursor: pointer;
    font-size: 1.2rem;
}

/* .facebook_icon {
    color: #1877f2;
}
.linkedin_icon {
    color: #0077b5;
} */

.instagram_icon {
    color: #4c68d7;
}

.footerBottom {
    background-color: #333;
    padding: 10px 0;
    color: #fff;
    text-align: center;
}

.footerBottom p {
    margin: 0;
    font-size: 0.9rem;
    padding: 0px 20px;
}

@media screen and (max-width: 800px) {
    .footer {
        flex-direction: column;
        z-index: 0;
    }

    .footer-left {
        width: calc(100% - 20px);
        flex-direction: column;
        display: flex;
        overflow: hidden;
        align-items: center;
        gap: 10px;
    }
}
