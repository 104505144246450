.home-courses {
  margin-top: 60px;
}
.home-courses-title {
  text-align: center;
  margin: 12px 0;
}
.home-courses-subtitle {
  text-align: center;
  color: gray;
  width: 95%;
}
