.custom-select {
    position: relative;
    font-family: Arial;
    border-radius: 6px;
}
.custom-select .link_botton{
    margin: 0;
    width: 60px;
    justify-content: center;
}
.custom-select a{
    color: rgb(246, 237, 237);
    padding: 8px 14px;
    background-color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    margin: 0;
    display: block;
    font-size:16px;
    line-height:1.3em;
    cursor: pointer;
    font-weight: 700;
    transition: .3s;

}
.custom-select a:hover{
    font-weight: 700;
    color: #30c801;
}
/* .custom-select .dropdown-content{
    padding-top: 16px;
} */
.custom-select select {
    background-color:var(--colors-bg);
    color: var(--colors-text);
    font-size: 18px;
    cursor: pointer;
}

