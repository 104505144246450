.home-contact {
  margin-top: 60px;
}
.home-contact-title {
  text-align: center;
  margin: 12px 0;
}
.home-contact-subtitle {
  text-align: center;
  color: gray;
}

.home-contact-block {
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contacts-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.contacts-form label {
  color: gray;
  margin: 20px 0 -10px 7px;
  background-color: var(--colors-bg);
  z-index: 1;width: fit-content;
  padding: 4px 8px;
  border: 1px solid #30c801;
  border-radius: 8px;
}
.contacts-form .inputs {
  padding: 12px 14px;
  outline: none;
  background: transparent;
  border: 2px solid #30c801;
  color: #30c801;
  border-radius: 12px;
  font-size: 18px;
}
.contacts-form .inputs:last-child {
  margin-top: 15px;
}
.contacts-form textarea {
    padding: 12px 14px;

  outline: none;
  background: transparent;
  border: 2px solid #30c801;
  color: #30c801;
  border-radius: 12px;
  font-size: 18px;
  resize: none;
  min-height: 130px;
}
.contact-btn {
  transition: 0.3s all linear;
  cursor: pointer;
  padding: 12px 14px;
  background-color: #30c801;
  color: var(--colors-text);  border: 2px solid #30c801;
  border-radius: 12px;
  font-size: 18px;
  margin-top: 20px;

}
.contact-btn:hover {
  background-color: transparent;
  color: #30c801;
}
  .home-contact-block-left {
    width: 55%;
  }
  .home-contact-block-left img{
    width: 100%;
  }
  .home-contact-block-right {
    width: 45%;
    padding-left: 20px;
  }

@media screen and (max-width: 800px) {
  .home-contact-block {
    width: 100%;
    justify-content: center;
  }
  .home-contact-block-left {
    width: 100%;
  }
  .home-contact-block-left img{
    width: 100%;
  }
  .home-contact-block-right {
    padding-left: 0;
    width: 100%;
  }
  .contacts-form {
    width: 100%;
  }
}
