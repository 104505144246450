.home-about {
  margin-top: 60px;
}
.home-about-title {
  text-align: center;
  margin: 0 0 24px;
}
.home-about-subtitle {
  text-align: center;
  color: gray;
}
.home-about-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-about-description-left {
  width: 45%;
  padding-right: 20px;
}
.home-about-description-right {
  width: 55%;
}
.home-about-description-right img{
  width: 100%;
  object-fit: cover;
}
.home-about-description-left-accardion-block {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .home-about-description {
    flex-direction: column;
  }

  .home-about-description-left {
    width: 100%;
    padding: 0;
  }
  .home-about-description-right {
    width: 100%;
  }
}
