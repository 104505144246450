.home-presentation {
    display: flex;
    align-items: center;
    justify-content: space-between;
        margin-top: 80px;
}
.home-presentation-left {
    color: #30c801;
    flex: 0 0 55%;
}

.home-presentation-left h1 {
    font-size: 60px;
    line-height: 1.5em;
}
.home-presentation-left-p {
    padding: 0 10px;
}
.home-presentation-left-btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.go-to-lesson {
    border: 2px solid #30c801;
    padding: 12px 14px;
    font-size: 18px;
    line-height: 1.3em;
    border-radius: 16px;
    transition: 0.3s all linear;
    margin-right: 14px;
    background: #30c801;
    color: var(--colors-text);
}

.go-to-lesson:hover {
    background: transparent;
    color: #30c801;
    cursor: pointer;
}
.home-presentation-left-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: absolute;
    right: 0px;
    padding: 40px 14px;
    top: 50%;
    right: -16px;
    transform: translate(0, -50%);
    -webkit-border-top-left-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background-color: #30c801;
    opacity: 0;
    transition: 0.5s all;
}

.home-presentation-left-social a {
    color: #161616;
}
.home-presentation-left-social svg {
    font-size: 30px;
    /* margin-right: 14px; */
    cursor: pointer;
}
/* 
body[data-theme="darck"] .home-presentation-left-social a {
    color: #30c801;
} */

.app_image {
    width: 100%;
}


.home-presentation-right {
    flex: 0 0 45%;
    position: relative;
}

.home-presentation-right:hover .home-presentation-left-social {
    transition: 0.5s all;
    opacity: 1;
}
@media screen and (max-width: 960px) {
    .home-presentation-left h1 {
        font-size: 45px;
    }
}
@media screen and (max-width: 800px) {
    

    .home-presentation {
        flex-direction: column;
    }
    .home-presentation-right {
        width: 100%;
        margin-top: 20px;
    }
    .app_image {
        width: 100%;
    }

}
@media screen and (max-width: 576px) {
    .home-presentation-left h1 {
        font-size: 32px;
    }
}
@media screen and (max-width: 380px) {
    .home-presentation-left h1 {
        font-size: 28px;
    }
    .home-presentation-left {
        width: 100%;
    }
}