.navigation a {
    margin: 0 14px;
    text-decoration: none;
    color: var(--colors-text);
    cursor: pointer;
    transition: 0.4s all linear;
    font-weight: 700;
}

.navigation a:hover {
    color: #30c801;
}

.link_botton {
    margin: 0 14px;
    font-size: 16px;
    text-decoration: none;
    font-size:16px;
    line-height:1.3em;
    color: var(--colors-text);
    cursor: default;
    transition: 0.3s;
    font-weight: 700;
    background: none;
    border: none;
    display: flex;
    align-items: center;
}

.link_botton:hover {
    color: #30c801;
}
.link_botton:hover .arrow_down{
    color: #30c801;
}

.dropbtn {
    background-color: #4caf50;
    color: white;
    /* padding: 16px; */
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    /* min-width: 160px; */
    padding-top: 20px;
    text-align: center;
    /* box-shadow: 0px 3px 16px 0px rgba(73, 73, 73, 0.2); */
    z-index: 1;
}

.dropdown-content a {
    color: rgb(246, 237, 237);
    padding: 8px 14px;
    background-color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    margin: 0;
    display: block;
    font-size:16px;
    line-height:1.3em;
}
/* 
.dropdown-content a:hover {
    background-color: #f1f1f14e;
} */

.dropdown:hover .dropdown-content {
    display: block;
}
.arrow_down{
    transition: 0.3s;
    margin-left: 3px;
}
 .dropdown:hover .arrow_down{
    transform: rotateX(180deg);
}
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

@media screen and (max-width: 800px) {
    .navigation {
        display: flex;
        flex-direction: column;
    }

    .navigation a {
        margin: 10px 14px;
    }

    .dropdown-content {
        position: relative;
        /* display: block; */
        padding: 0;
        text-align: left;
    }
    .dropdown-content a {
        background-color: transparent;
        color: var(--colors-text);
    }
}
