.global-functions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.global-functions span {
    font-size: 22px;
    height: 22px;
    transition: 0.3s all linear;
    cursor: pointer;
}

.global-functions span:hover {
    color: #30c801;
}
