.home-courses-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1000px;
    margin: 20px auto;
    flex-wrap: wrap;
    box-shadow: 1.5px 1.5px 15px rgb(0 0 0 / 5%);
    border: 2px solid #30c801;
    border-radius: 16px;
}

.home-courses-card {
    width: 100%;
    padding: 15px;
    transition: 0.3s all linear;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.home-courses-card svg {
    font-size: 24px;
    float: left;
    cursor: pointer;
    color: #30c801;
}

.home-courses-card-title {
    text-align: center;
    font-size: 18px;
}

.home-courses-description {
    text-align: justify;
    font-style: italic;
    font-weight: 400;
    padding: 0 15px 15px 15px;
}
.arrow{
    
    transition: .3s;
}
.arrow_rotate{
    transform: rotateX(180deg);
}
@media screen and (max-width: 800px) {
    .home-courses-main {
        width: 100%;
    }
}
