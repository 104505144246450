.header_container {
  width: 100%;
  overflow: hidden;
}
.header {
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  background: var(--colors-bg);
  box-shadow: var(--shadow);
  z-index: 100;
}
.headerInner {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px;
}

.header_left_block {
  display: flex;
  align-items: center;
  gap: 14px;
}

.burger_menu {
  position: fixed;
  display: none;
  top: 10px;
  right: 16px;
  width: 40px;
  height: auto;
  cursor: pointer;
  display: none;
  z-index: 999;
}

.menu_button {
  font-size: 40px;
  z-index: 200;
}

.logo_img {
  width: 60px;
  position: absolute;
  top: 0;
}

.header-logo-moble {
  /* position: absolute; */
  display: none;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--colors-bg);
  box-shadow: var(--shadow);
  z-index: 99;
}

@media screen and (max-width: 800px) {
  .header {
    display: flex;
    flex-direction: column;
    /* height: 280px; */
    transition: 0.5s;
    display: none;
  }
  .logo_img {
    left: 16px;
  }
  .header_left_block {
    flex-direction: column;
    gap: 15px;
  }
  .header-logo-moble {
    display: block;
  }

  .header-logo {
    display: none;
  }
  .burger_menu {
    display: flex;
  }
  .headerInner {
    z-index: 99999;
    justify-content: center;
  }
}
