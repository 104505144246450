.home-center-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home-center-title {
  text-align: center;
  padding-bottom: 30px;
}
.home-center-subtitle {
  text-align: center;
  color: gray;
}
.home-center-block-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}
.home-center-block-left img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
}
.home-center-block-right {
  width: 60%;
}
.home-center-block-right h5 {
  font-size: 19px;
  text-align: center;
  margin: 15px;
}
.home-center-block-right {
  padding-left: 20px;
}
.home-center-block-right p {
  text-align: justify;
  margin-bottom: 8px;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .home-center-block-left {
    width: 100%;
  }
.home-center-block-left img {
  width: 90%;
  margin-top: 0;
}
  .home-center-block-right {
    width: 100%;
    padding: 20px 0 0 0;
  }
}
@media screen and (max-width: 800px) {
  .home-center-block {
    flex-direction: column;
  }
  .home-courses-main {
    width: 95%;
  }
  .home-center-block-left {
    width: 100%;
    margin: 0;
  }
}
